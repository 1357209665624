export const DEFAULT_THING_MAP_POPUP = {
  op: 'layout-grid',
  template: [
    ['thing', 'thing', 'thing'],
    ['indicator', 'indicator', 'indicator'],
    ['address', 'address', 'address'],
    ['person', 'person', 'person'],
    ['tags', 'tags', 'tags'],
    ['last', 'last', 'last'],
  ],
  thing: {
    op: 'ui',
    name: 'Box',
    sx: {
      mb: 2,
    },
    children: [{
      op: 'ui',
      key: 'data',
      name: 'DisplayColumn',
      link: true,
      linkTestId: 'thing-map-popup-display',
      sx: {
        overflow: 'hidden',
      },
      data: {
        op: 'context',
      },
    },
    {
      op: 'if-feature-data',
      then: {
        op: 'ui',
        name: 'Indicator',
        color: {
          op: 'if',
          condition: {
            op: 'before-past',
            timestamp: {
              op: 'feature-latest-occurred',
              feature: 'tracking',
              havingPath: 'data.location',
            },
            duration: 'P7D',
          },
          then: {
            op: 'theme-property',
            path: 'states.uncertain',
          },
          else: {
            op: 'if',
            condition: { op: 'macro:is-power-on' },
            then: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'theme-property',
                path: 'states.on',
              },
              else: {
                op: 'theme-property',
                path: 'states.paused',
              },
            },
            else: {
              op: 'if',
              condition: { op: 'macro:is-moving' },
              then: {
                op: 'theme-property',
                path: 'states.on',
              },
              else: {
                op: 'theme-property',
                path: 'states.off',
              },
            },
          },
        },
        children: {
          op: 'ui',
          name: 'Typography',
          variant: 'caption',
          'data-testid': 'thing-map-popup-indicator',
          children: {
            op: 'if',
            condition: {
              op: 'before-past',
              timestamp: {
                op: 'feature-latest-occurred',
                feature: 'tracking',
                havingPath: 'data.location',
              },
              duration: 'P7D',
            },
            then: {
              op: 'translate',
              translation: 'page.thing-map.indicator.unknown.label',
              replace: {
                count: 7,
                format: 'days',
              },
              options: {
                ns: 'track',
              },
            },
            else: {
              op: 'if',
              condition: { op: 'macro:is-power-on' },
              then: {
                op: 'if',
                condition: { op: 'macro:is-moving' },
                then: {
                  op: 'translate',
                  translation: 'page.thing-map.indicator.powered-on-moving.label',
                  speed: {
                    op: 'format-speed',
                    value: {
                      op: 'feature-latest',
                      feature: 'tracking',
                      havingPath: 'data.velocity',
                      path: 'data.velocity.speed',
                      excludeEventTypes: ['inaccurate-location-update'],
                    },
                  },
                  options: {
                    ns: 'track',
                  },
                },
                else: {
                  op: 'translate',
                  translation: 'page.thing-map.indicator.stationary.label',
                  options: {
                    ns: 'track',
                  },
                },
              },
              else: {
                op: 'if',
                condition: { op: 'macro:is-moving' },
                then: {
                  op: 'translate',
                  translation: 'page.thing-map.indicator.unpowered-moving.label',
                  speed: {
                    op: 'format-speed',
                    value: {
                      op: 'feature-latest',
                      feature: 'tracking',
                      havingPath: 'data.velocity',
                      path: 'data.velocity.speed',
                      excludeEventTypes: ['inaccurate-location-update'],
                    },
                  },
                  options: {
                    ns: 'track',
                  },
                },
                else: {
                  op: 'translate',
                  translation: 'page.thing-map.indicator.powered-off.label',
                  options: {
                    ns: 'track',
                  },
                },
              },
            },
          },
        },
      },
    }],
  },
  person: {
    op: 'if',
    condition: {
      op: 'object:has',
      path: 'thingPersons[0]',
      object: {
        op: 'context',
      },
    },
    then: {
      op: 'ui',
      name: 'ThingPersonList',
      sx: {
        mb: 2,
      },
      data: {
        op: 'context',
        path: 'thingPersons',
      },
    },
  },
  address: {
    op: 'ui',
    name: 'Box',
    sx: {
      mb: 2,
    },
    children: {
      op: 'ui',
      name: 'EventAddress',
      'data-testid': 'thing-map-popup-address',
      location: {
        op: 'feature-latest',
        feature: 'tracking',
        havingPath: 'data.location',
        path: 'data.location',
        excludeEventTypes: ['inaccurate-location-update'],
      },
      variant: 'body2',
      addressUnavailableText: {
        op: 'translate',
        translation: 'common:page.thing-map.address-unavailable.hint',
      },
      textId: 'thing-map-popup-address',
    },
  },
  tags: {
    op: 'if',
    condition: {
      op: 'object:has',
      path: 'tags[0]',
      object: {
        op: 'context',
      },
    },
    then: {
      op: 'ui',
      name: 'Tags',
      sx: {
        mb: 2,
      },
      tags: {
        op: 'context',
        path: 'tags',
      },
      'data-testid': 'thing-map-popup-groups',
    },
  },
  last: {
    op: 'ui',
    name: 'Stack',
    direction: 'row',
    alignItems: 'center',
    gap: 1,
    children: [{
      op: 'ui',
      name: 'DynamicIcon',
      icon: {
        op: 'ui',
        name: 'Icon',
        icon: 'LastContactIcon',
      },
      color: {
        op: 'if',
        condition: {
          op: 'before-past',
          timestamp: {
            op: 'last-event-time',
          },
          duration: 'PT30M',
        },
        then: {
          op: 'theme-property',
          path: 'lastContact.offline',
        },
        else: {
          op: 'if',
          condition: {
            op: 'before-past',
            timestamp: {
              op: 'last-event-time',
            },
            duration: 'PT5M',
          },
          then: {
            op: 'theme-property',
            path: 'lastContact.recent',
          },
          else: {
            op: 'theme-property',
            path: 'lastContact.current',
          },
        },
      },
    }, {
      op: 'ui',
      name: 'Typography',
      component: 'div',
      variant: 'caption',
      children: [
        {
          op: 'translate',
          translation: 'component.last-contact.labels.contact-time',
          options: {
            ns: 'common',
          },
        },
        {
          op: 'ui',
          name: 'FormatTimestamp',
          format: 'relative',
          value: {
            op: 'last-event-time',
          },
        },
      ],
    }],
  },
};
