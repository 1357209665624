import { Box, Typography } from '@mui/material';
import { ResponsiveStyleValue, SxProps } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  'data-testid'?: string;
  direction?: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  label: string;
  sx?: SxProps;
}

export const LabelIcon: FC<Props> = ({ children, label, sx, direction, ...props }) => {
  return <Box sx={{ display: 'flex', flexDirection: direction, textAlign: 'center', ...sx }} data-testid={props['data-testid']}>
    <Box>{children}</Box>
    <Typography sx={{ margin: 0 }} variant='caption'>{label}</Typography>
  </Box>;
};
