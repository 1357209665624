export const TRACK_THING_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    justifyContent: 'space-between',
    '.ListPaperColumn-root.property:nth-of-type(n + 4)': {
      display: 'none',
    },
    '> .ListPaperColumn-root:first-child:nth-last-child(3), .ListPaperColumn-root:first-child:nth-last-child(3) ~ .ListPaperColumn-root': {
      '&.ListPaperColumn-root.property': {
        width: '50%',
      },
    },
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
      },
    },
    {
      op: 'array:map',
      items: [
        { path: 'sharedProperties', index: 0 },
        { path: 'sharedProperties', index: 1 },
        { path: 'sharedProperties', index: 2 },
        { path: 'sharedProperties', index: 3 },
        { path: 'sharedProperties', index: 4 },
        { path: 'sharedProperties', index: 5 },
        { path: 'properties', index: 0 },
        { path: 'properties', index: 1 },
        { path: 'properties', index: 2 },
        { path: 'properties', index: 3 },
        { path: 'properties', index: 4 },
        { path: 'properties', index: 5 },
      ],
      to: {
        op: 'if',
        condition: {
          op: 'ne',
          a: {
            op: 'object-value-by-index',
            index: {
              op: 'context',
              path: ['item', 'index'],
            },
            object: {
              op: 'context',
              path: [
                'context', {
                  op: 'context',
                  path: ['item', 'path'],
                },
              ],
            },
          },
          b: null,
        },
        then: {
          op: 'ui',
          name: 'BasicColumnHighlightedSearch',
          type: 'property',
          label: {
            op: 'object-key-by-index',
            index: {
              op: 'context',
              path: ['item', 'index'],
            },
            object: {
              op: 'context',
              path: [
                'context',
                {
                  op: 'context',
                  path: ['item', 'path'],
                },
              ],
            },
          },
          children: {
            op: 'if',
            condition: {
              op: 'and',
              values: [{
                op: 'eq',
                a: {
                  op: 'object-key-by-index',
                  index: {
                    op: 'context',
                    path: ['item', 'index'],
                  },
                  object: {
                    op: 'context',
                    path: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                    ],
                  },
                },
                b: 'brand',
              }, {
                op: 'eq',
                a: {
                  op: 'context',
                  path: ['item', 'path'],
                },
                b: 'sharedProperties',
              }],
            },
            then: {
              op: 'ui',
              name: 'FetchOneColumn',
              id: {
                op: 'context',
                path: {
                  op: 'if',
                  condition: {
                    op: 'has',
                    path: 'value',
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'brand',
                      ],
                    },
                  },
                  then: [
                    'context',
                    {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    'brand',
                    'value',
                  ],
                  else: [
                    'context',
                    {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    'brand',
                  ],
                },
              },
              dataType: 'brand',
            },
            else: {
              op: 'if',
              condition: {
                op: 'and',
                values: [{
                  op: 'eq',
                  a: {
                    op: 'object-key-by-index',
                    index: {
                      op: 'context',
                      path: ['item', 'index'],
                    },
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                      ],
                    },
                  },
                  b: 'model',
                }, {
                  op: 'eq',
                  a: {
                    op: 'context',
                    path: ['item', 'path'],
                  },
                  b: 'sharedProperties',
                }],
              },
              then: {
                op: 'ui',
                name: 'FetchOneColumn',
                id: {
                  op: 'context',
                  path: {
                    op: 'if',
                    condition: {
                      op: 'has',
                      path: 'value',
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                          'model',
                        ],
                      },
                    },
                    then: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                      'model',
                      'value',
                    ],
                    else: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                      'model',
                    ],
                  },
                },
                dataType: 'model',
              },
              else: {
                op: 'if',
                condition: {
                  op: 'and',
                  values: [{
                    op: 'eq',
                    a: {
                      op: 'object-key-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                    b: 'variant',
                  }, {
                    op: 'eq',
                    a: {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    b: 'sharedProperties',
                  }],
                },
                then: {
                  op: 'ui',
                  name: 'FetchOne',
                  id: {
                    op: 'context',
                    path: {
                      op: 'if',
                      condition: {
                        op: 'has',
                        path: 'value',
                        object: {
                          op: 'context',
                          path: [
                            'context',
                            {
                              op: 'context',
                              path: ['item', 'path'],
                            },
                            'variant',
                          ],
                        },
                      },
                      then: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'variant',
                        'value',
                      ],
                      else: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'variant',
                      ],
                    },
                  },
                  dataType: 'variant',
                },
                else: {
                  op: 'if',
                  condition: {
                    op: 'has',
                    path: 'value',
                    object: {
                      op: 'object-value-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                  },
                  then: {
                    op: 'get',
                    path: 'value',
                    object: {
                      op: 'object-value-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                  },
                  else: {
                    op: 'object-value-by-index',
                    index: {
                      op: 'context',
                      path: 'item.index',
                    },
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
        else: null,
      },
    },
    {
      op: 'ui',
      name: 'GroupColumn',
      data: {
        op: 'context',
      },
    },
  ],
};

export const ADMIN_THING_LIST_ROW_DEFAULT_TEMPLATE = {
  op: 'ui',
  name: 'ListRow',
  sx: {
    '.ListPaperColumn-root.property:nth-of-type(n + 5)': {
      display: 'none',
    },
  },
  children: [
    {
      op: 'ui',
      name: 'DisplayColumn',
      data: {
        op: 'context',
      },
    },
    {
      op: 'array:map',
      items: [
        { path: 'sharedProperties', index: 0 },
        { path: 'sharedProperties', index: 1 },
        { path: 'sharedProperties', index: 2 },
        { path: 'properties', index: 0 },
        { path: 'properties', index: 1 },
        { path: 'properties', index: 2 },
      ],
      to: {
        op: 'if',
        condition: {
          op: 'ne',
          a: {
            op: 'object-value-by-index',
            index: {
              op: 'context',
              path: ['item', 'index'],
            },
            object: {
              op: 'context',
              path: [
                'context', {
                  op: 'context',
                  path: ['item', 'path'],
                },
              ],
            },
          },
          b: null,
        },
        then: {
          op: 'ui',
          name: 'BasicColumnHighlightedSearch',
          type: 'property',
          label: {
            op: 'object-key-by-index',
            index: {
              op: 'context',
              path: ['item', 'index'],
            },
            object: {
              op: 'context',
              path: [
                'context',
                {
                  op: 'context',
                  path: ['item', 'path'],
                },
              ],
            },
          },
          children: {
            op: 'if',
            condition: {
              op: 'and',
              values: [{
                op: 'eq',
                a: {
                  op: 'object-key-by-index',
                  index: {
                    op: 'context',
                    path: ['item', 'index'],
                  },
                  object: {
                    op: 'context',
                    path: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                    ],
                  },
                },
                b: 'brand',
              }, {
                op: 'eq',
                a: {
                  op: 'context',
                  path: ['item', 'path'],
                },
                b: 'sharedProperties',
              }],
            },
            then: {
              op: 'ui',
              name: 'FetchOneColumn',
              id: {
                op: 'context',
                path: {
                  op: 'if',
                  condition: {
                    op: 'has',
                    path: 'value',
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'brand',
                      ],
                    },
                  },
                  then: [
                    'context',
                    {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    'brand',
                    'value',
                  ],
                  else: [
                    'context',
                    {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    'brand',
                  ],
                },
              },
              dataType: 'brand',
            },
            else: {
              op: 'if',
              condition: {
                op: 'and',
                values: [{
                  op: 'eq',
                  a: {
                    op: 'object-key-by-index',
                    index: {
                      op: 'context',
                      path: ['item', 'index'],
                    },
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                      ],
                    },
                  },
                  b: 'model',
                }, {
                  op: 'eq',
                  a: {
                    op: 'context',
                    path: ['item', 'path'],
                  },
                  b: 'sharedProperties',
                }],
              },
              then: {
                op: 'ui',
                name: 'FetchOneColumn',
                id: {
                  op: 'context',
                  path: {
                    op: 'if',
                    condition: {
                      op: 'has',
                      path: 'value',
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                          'model',
                        ],
                      },
                    },
                    then: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                      'model',
                      'value',
                    ],
                    else: [
                      'context',
                      {
                        op: 'context',
                        path: ['item', 'path'],
                      },
                      'model',
                    ],
                  },
                },
                dataType: 'model',
              },
              else: {
                op: 'if',
                condition: {
                  op: 'and',
                  values: [{
                    op: 'eq',
                    a: {
                      op: 'object-key-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                    b: 'variant',
                  }, {
                    op: 'eq',
                    a: {
                      op: 'context',
                      path: ['item', 'path'],
                    },
                    b: 'sharedProperties',
                  }],
                },
                then: {
                  op: 'ui',
                  name: 'FetchOne',
                  id: {
                    op: 'context',
                    path: {
                      op: 'if',
                      condition: {
                        op: 'has',
                        path: 'value',
                        object: {
                          op: 'context',
                          path: [
                            'context',
                            {
                              op: 'context',
                              path: ['item', 'path'],
                            },
                            'variant',
                          ],
                        },
                      },
                      then: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'variant',
                        'value',
                      ],
                      else: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                        'variant',
                      ],
                    },
                  },
                  dataType: 'variant',
                },
                else: {
                  op: 'if',
                  condition: {
                    op: 'has',
                    path: 'value',
                    object: {
                      op: 'object-value-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                  },
                  then: {
                    op: 'get',
                    path: 'value',
                    object: {
                      op: 'object-value-by-index',
                      index: {
                        op: 'context',
                        path: ['item', 'index'],
                      },
                      object: {
                        op: 'context',
                        path: [
                          'context',
                          {
                            op: 'context',
                            path: ['item', 'path'],
                          },
                        ],
                      },
                    },
                  },
                  else: {
                    op: 'object-value-by-index',
                    index: {
                      op: 'context',
                      path: 'item.index',
                    },
                    object: {
                      op: 'context',
                      path: [
                        'context',
                        {
                          op: 'context',
                          path: ['item', 'path'],
                        },
                      ],
                    },
                  },
                },
              },
            },
          },
        },
        else: null,
      },
    },
  ],
};
