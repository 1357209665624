import { Json, PropertyDefinition, ReferencePropertyDefinition, TypeDefinitionTypes } from '@eagle/common';
import { FC } from 'react';
import FormatDate from './format-date';
import FormatNumber from './format-number';
import FormatReference from './format-reference';
import FormatText from './format-text';
import FormatTimestamp from './format-timestamp';
import { getMaybePropertyValue } from './format.utils';

interface Props {
  definition: PropertyDefinition;
  value: Json;
}

export const FormatProperty: FC<Props> = ({ value: rawValue, definition }) => {
  const formatProperty = (): JSX.Element | Json => {
    const value = getMaybePropertyValue(rawValue);

    switch (definition.type) {
      case TypeDefinitionTypes.TEXT:
      case TypeDefinitionTypes.BOOLEAN:
        return <FormatText value={value as string} />;
      case TypeDefinitionTypes.NUMBER:
      case TypeDefinitionTypes.INTEGER:
        return <FormatNumber value={value as number} format={definition.format} type={definition.type} />;
      case TypeDefinitionTypes.DATE:
        return <FormatDate value={value as string} format={definition.format} />;
      case TypeDefinitionTypes.TIMESTAMP:
        return <FormatTimestamp value={value as string} format={definition.format} />;
      case TypeDefinitionTypes.REFERENCE:
        return <FormatReference value={value as string} definition={definition as ReferencePropertyDefinition} />;
      default:
        return value as Json;
    }
  };

  return <>{formatProperty()}</>;
};

export default FormatProperty;
