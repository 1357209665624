import { Box, Link, Stack, styled, Typography } from '@mui/material';
import chroma from 'chroma-js';
import { FC, ReactNode } from 'react';

interface Props {
  avatar?: ReactNode;
  label?: ReactNode;
  title?: ReactNode;
  hint?: ReactNode;
  indicator?: ReactNode;
  href?: string;
  wrap?: boolean;
}

export const DataCard: FC<Props> = ({ avatar, indicator, label, title, hint, href, wrap }) => {
  const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    alignItems: 'center',
    borderRadius: theme.dataCard.borderRadius,
    background: theme.dataCard.background,
    color: theme.dataCard.contrastText,
    width: '100%',
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
    '& .avatar': {
      width: '60px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& img': {
        fontSize: '3rem',
        maxWidth: '1em',
        maxHeight: '1em',
      },
      '& svg': {
        fontSize: '2rem',
      },
    },
    '& .indicator': {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
    '& .content': {
      width: '100%',
    },
    '& .label, .title, .hint': {
      overflowX: 'hidden',
      whiteSpace: wrap ? 'normal' : 'no-wrap',
      textOverflow: wrap ? 'inherit' : 'ellipsis',
    },
    '& a': {
      textDecoration: 'none',
    },
    '& .label, .hint': {
      color: chroma(theme.dataCard.contrastText).alpha(0.7).hex(),
    },
  }));

  return <Container>
    {indicator ? <Box className='indicator'>{indicator}</Box> : <></>}
    <Stack alignItems='center' direction='row' gap={1} height='100%' >
      {avatar ? <Box className='avatar'>{avatar}</Box> : <></>}
      <Box className='content'>
        <Stack direction='column'>
          {label ? <Typography className='label' component='div' variant='dataCardLabel'>{label}</Typography> : <></>}
          {title ? <Typography className='title' component='div' variant='dataCardTitle'>{href ? <Link component='a' href={href}>{title}</Link> : title}</Typography> : <></>}
          {hint ? <Typography className='hint' component='div' variant='dataCardHint'>{hint}</Typography> : <></>}
        </Stack>
      </Box>
    </Stack>
  </Container>;
};
