import { Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { RadioButtonOption, RadioButtonSet } from '../radio-button-set/radio-button-set';
import { ConfirmDialogConfirmation } from './confirm-dialog-confirmation';

interface Props {
  confirmPrompt: JSX.Element;
  'data-testid'?: string;
  disabled?: boolean;
  heading?: string;
  onConfirm: (selected: string) => Promise<unknown>;
  onFailure: () => void;
  onSuccess: () => void;
  open: boolean;
  options: RadioButtonOption[];
  radioSize?: 'small' | 'medium';
  selected: string;
  setOpen: (open: boolean) => void;
  setSelected: (value: string) => unknown;
}

export const ConfirmDialogRadio: FC<Props> = ({
  confirmPrompt,
  disabled,
  heading,
  onConfirm,
  onFailure,
  onSuccess,
  open = false,
  options,
  radioSize = 'small',
  selected,
  setOpen,
  setSelected,
  ...props
}): JSX.Element => {
  const [confirmSelected, setConfirmSelected] = useState(selected);

  const onSelected = (selectedValue: string): void => {
    setSelected(selectedValue);
    setOpen(true);
  };

  const handleOnConfirm = (value: string): Promise<unknown> => {
    setConfirmSelected(value);
    return onConfirm(value);
  };

  return (
    <Stack data-testid={props['data-testid']} sx={{ my: open ? .5 : 'auto' }}>
      {heading
        && <Typography variant="subtitle2">{heading}</Typography>
      }
      <Stack direction="row" alignItems="center">
        {!open
          ? <RadioButtonSet
            data-testid="radio-set"
            disabled={disabled}
            options={options}
            radioSize={radioSize}
            selected={selected}
            setSelected={onSelected}
          />
          : <ConfirmDialogConfirmation
            confirmPrompt={confirmPrompt}
            onCancel={() => setSelected(confirmSelected)}
            onConfirm={() => handleOnConfirm(selected)}
            onFailure={onFailure}
            onSuccess={onSuccess}
            setOpen={setOpen}
            stackDirection={'row'}
          />
        }
      </Stack>
    </Stack>
  );
};

export default ConfirmDialogRadio;
