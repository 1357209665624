import { Chip, Theme, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

interface Props {
  align?: 'left' | 'center' | 'right';
  background?: string;
  color?: string;
  'data-testid'?: string;
  fullWidth?: boolean;
  icon?: JSX.Element;
  label?: string;
  tooltip?: string;
  subtitle?: string;
}

export const LabelledBadge: FC<Props> = ({ align, background, color, tooltip = '', fullWidth, subtitle, label, ...props }) => {
  const styles: SxProps<Theme> = {
    backgroundColor: !background ? (theme) => theme.palette.primary.main : background,
    color: !color ? (theme) => theme.palette.primary.contrastText : color,
    width: fullWidth ? '100%' : 'auto',
    borderRadius: 1,
    height: 'auto',
    justifyContent: align || 'center',
    textAlign: align || 'center',
    minHeight: 32,
    py: .75,
    fontWeight: '500',
    '.MuiChip-icon': {
      color: 'inherit',
      fontSize: '1rem',
    },
  };

  return (
    <Tooltip arrow title={tooltip}>
      <Chip sx={styles} label={
        <>
          <Typography variant="subtitle2" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', lineHeight: 1.2, textAlign: 'inherit' }}>{label}</Typography>
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </>
      } {...props} />
    </Tooltip>
  );
};
