import { FC } from 'react';
import { ConfirmDialogRadio } from '../../components';
import { RadioButtonOption } from './subscription-detail.types';

interface Props {
  'data-testid'?: string;
  fieldDisabled?: boolean;
  heading?: string;
  options: RadioButtonOption[];
  selected: string;
  setSelected: (value: string) => unknown;
}

export const NotificationRecipientRadioButtons: FC<Props> = ({ fieldDisabled, heading, options, selected, setSelected, ...props }) => (
  <ConfirmDialogRadio
    confirmPrompt={<></>}
    disabled={fieldDisabled}
    heading={heading}
    onConfirm={() => Promise.resolve()}
    onFailure={() => { }}
    onSuccess={() => { }}
    open={false}
    options={options}
    selected={selected}
    setOpen={() => { }}
    setSelected={setSelected}
    data-testid={props['data-testid'] ?? 'radio-dialog'}
  />
);
