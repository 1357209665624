/* eslint-disable react-hooks/exhaustive-deps */
import { MarkerType } from '@eagle/api-types';
import { useTheme } from '@mui/material';
import chroma from 'chroma-js';
import L, { Direction, PointExpression } from 'leaflet';
import { debounce, isString } from 'lodash';
import { CSSProperties, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { useFetchOneCache, usePromiseConditional } from '../../hooks';
import { makeStyles } from '../../theme';
import { CommonEntity, Undefinable } from '../../types';
import { CacheDataTypes } from '../../types/cache';
import { getMarkerTemplate, useMapContext } from '../../util/maps';
import { FieldLoading } from '../field';
import { Icon } from '../icons/widget-icon';

export interface MapMarkerTooltipProps {
  enableClick?: boolean;
  background?: string;
  color?: string;
  direction?: Direction;
  offset?: PointExpression;
  permanent?: boolean;
  styles?: CSSProperties;
}

export interface MapMarkerProps {
  cacheDataType: CacheDataTypes;
  disableMarkerClick?: boolean;
  'data-testid'?: string;
  entityId: string;
  position: L.LatLngExpression;
  background?: string;
  borderColor?: string;
  markerType?: MarkerType;
  indicator?: string;
  icon?: ReactNode | string;
  iconColor?: string;
  image?: string;
  tooltip?: MapMarkerTooltipProps;
  tooltipContent?: JSX.Element;

}

/**
  * NATIVE FUNCTION: Display map marker
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function MapMarker<T extends CommonEntity<unknown>>({ cacheDataType, disableMarkerClick, entityId, position, background, borderColor, markerType, icon, iconColor, image, indicator, tooltip, tooltipContent, ...props }: MapMarkerProps): JSX.Element {
  const { handleMarkerClick } = useMapContext();
  const [markerCount, setMarkerCount] = useState(0);
  const entityCache = useFetchOneCache(cacheDataType);
  const markerReference = useRef<L.Marker>(null);
  const tooltipRef = useRef<L.Tooltip>(null);
  const theme = useTheme();
  const marker = markerType || MarkerType.PIN;
  const defaultOffset: PointExpression = marker === MarkerType.PIN ? [0, 37] : [0, 45];
  const markerBackground = background || theme.marker.background;
  const iconComponent = isString(icon) ? <Icon icon={icon} /> : icon;
  const [entityData] = usePromiseConditional<Undefinable<T>>(() => entityCache.one(entityId), () => markerCount === 0, [markerCount]);
  const template = getMarkerTemplate(marker, iconComponent, image, markerBackground, iconColor, indicator, borderColor);

  const updateMarkerCount = useCallback(() => debounce((e: L.LeafletEvent) => {
    setMarkerCount((e.target as L.Marker).getElement()?.childElementCount ?? 0);
  }, 200), []);

  const { classes } = makeStyles()(() => ({
    tooltip: {
      backgroundColor: chroma(theme.palette.background.default).alpha(0.8).css(),
      pointerEvents: tooltip?.enableClick ? 'auto' : 'none',
      cursor: tooltip?.enableClick ? 'pointer' : 'none',
      ...tooltip?.styles,
    },
  }))();

  const handleClick = {
    click: () => {
      if (!disableMarkerClick) {
        handleMarkerClick(entityId);
      }
    },
    add: updateMarkerCount,
  };

  const handleTooltipClick = (): void => {
    if (!disableMarkerClick) {
      handleMarkerClick(entityId);
    }
  };

  useEffect(() => {
    const tooltipElement = tooltipRef.current?.getElement();
    if (tooltipElement) {
      tooltipElement.addEventListener('click', handleTooltipClick);
    }
    return () => {
      if (tooltipElement) {
        tooltipElement.removeEventListener('click', handleTooltipClick);
      }
    };
  }, []);
  return (
    <Marker
      icon={template}
      ref={markerReference}
      eventHandlers={handleClick}
      position={position}
    >
      <Tooltip ref={tooltipRef} className={classes.tooltip} permanent={tooltip?.permanent || true} direction={tooltip?.direction || 'center'} offset={tooltip?.offset || defaultOffset}>
        {tooltipContent ? tooltipContent : entityData ? <span data-chromatic="ignore" data-testid={props['data-testid']} title={entityData.display}>{entityData.display}</span> : <FieldLoading />}
      </Tooltip>
    </Marker>
  );
}
