/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { WatRequest } from '@eagle/core-data-types';
import { useSnackbar } from 'notistack';
import { FC, useMemo, useState } from 'react';
import { useAuthenticated } from '../../auth';
import { useConfig, usePromise, useScript } from '../../hooks';
import { ErrorPage } from '../../pages';
import { Nullable, Undefinable } from '../../types';
import { CodedError } from '../../util';
import { getSisenseLanguageCode } from '../../util/sisense';
import { MiddleSpinner } from '../middle-spinner';
import { SisenseFrameConstructor } from './types';

interface Props {
  dashboardId: string;
}

interface DashboardProps extends Props {
  sisenseUrl: string;
  wat: string;
  theme?: string;
}

export const Dashboard: FC<DashboardProps> = ({ dashboardId, sisenseUrl, wat, theme }) => {
  const [iframeRef, setIframeRef] = useState<Nullable<HTMLIFrameElement>>(null);
  const { enqueueSnackbar } = useSnackbar();

  const sisenseEmbed = window['sisense.embed'] as Undefinable<{ SisenseFrame: SisenseFrameConstructor }>;

  useMemo(() => {
    if (!iframeRef) return undefined;
    if (!sisenseEmbed) {
      console.log("window['sisense.embed'] is undefined");
      return undefined;
    }

    const { SisenseFrame } = sisenseEmbed;
    const sisenseFrame = new SisenseFrame({
      // Sisense application URL, including protocol and port if required
      url: sisenseUrl,
      // Web access token:
      wat,
      // OID of dashboard to load initially
      dashboard: dashboardId,
      // Which panels to show in the iFrame
      settings: {
        showLeftPane: false,
        showToolbar: true,
        showRightPane: true,
      },
      element: iframeRef,
      theme,
      language: getSisenseLanguageCode(),
    });

    sisenseFrame.render().catch((err: Error) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    });
  }, [dashboardId, iframeRef, sisenseEmbed, wat]);

  return <iframe ref={setIframeRef} style={{ border: 'none', height: '100%' }} />;
};

export const SisenseDashboard: FC<Props> = ({ dashboardId }) => {
  const config = useConfig() as { sisense: { baseUrl: string; theme?: string } };
  const sisenseUrl = config?.sisense?.baseUrl;
  const sisenseTheme = config?.sisense?.theme;
  if (!sisenseUrl) return <ErrorPage error={new CodedError('Reporting is not configured', 'error-sisense-not-configured')} />;

  const scriptUrl = `${sisenseUrl}/js/frame.js`;
  const state = useScript(scriptUrl);
  const { restClient } = useAuthenticated();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const requestData: WatRequest = { timeZone };

  const [data, error] = usePromise(() => restClient.sisense.wat(requestData), [dashboardId]);

  if (state.error) {
    return <ErrorPage error={new CodedError('Unable to load report', 'error-loading-analytics-scripts')} />;
  }
  if (error) return <ErrorPage error={error} />;
  if (!data) return <MiddleSpinner />;

  const { wat } = data;
  return <Dashboard dashboardId={dashboardId} sisenseUrl={sisenseUrl} wat={wat} theme={sisenseTheme} />;
};
