import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { OverflowTooltip } from '../tooltip';

interface Props extends PropsWithChildren {
  boxSx?: SxProps;
  className?: string;
  'data-testid'?: string;
  'data-testid-inner'?: string;
  label: ReactNode;
  reverse?: boolean;
  sx?: SxProps;
  width?: number | string;
  wordWrap?: boolean;
  wrap?: boolean;
  deleted?: boolean;
}

export const ListPaperColumn: FC<Props> = ({ boxSx, children, className, label: labelContent, reverse, sx, width, deleted = false, wrap = false, wordWrap, ...props }) => (
  <Stack data-testid={props['data-testid']} className={`ListPaperColumn-root ${className || ''}`} sx={{ textDecoration: deleted ? 'line-through!important' : 'none', flexDirection: reverse ? 'column-reverse' : 'column', ...boxSx, ...sx }}>
    <Typography
      component="label"
      sx={{
        opacity: 0.8,
        cursor: 'pointer',
        fontSize: '0.8rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {labelContent}
    </Typography>
    <OverflowTooltip
      data-testid={props['data-testid-inner']}
      deleted={deleted}
      sx={{
        whiteSpace: wrap ? 'normal' : 'nowrap',
        wordWrap: wordWrap ? 'break-word' : 'normal',
        textOverflow: wrap ? 'clip' : 'ellipsis',
      }}
    >
      {children}
    </OverflowTooltip>
  </Stack>
);
