import { Person, PersonThingRange } from '@eagle/core-data-types';
import { Link, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';
import { useHref } from 'react-router-dom';
import { CacheDataTypes } from '../../types';
import { testid } from '../../util';
import { FetchOne } from '../fetch';

interface Props {
  data: PersonThingRange[];
  sx?: SxProps;
}

export const ThingPersonList: FC<Props> = ({ data, sx }): JSX.Element => {
  const href = useHref('/person');

  if (!data.length) return <></>;

  return (
    <Stack direction="row" sx={sx}>
      {data.map((thingPerson, i: number) =>
        <Stack key={i}>
          {i !== 0 && <Typography color="text.primary" variant="caption">{' | '}</Typography>}
          <FetchOne
            dataType={CacheDataTypes.PERSON}
            id={thingPerson.personId}
            renderFactory={(person: Person) => (
              <Link
                data-testid={testid`thing-map-popup-person-${person._id}`}
                href={`${href}/${person._id}`}
                sx={{ cursor: 'pointer' }}
                variant="caption"
              >
                {person.display}
              </Link>
            )}
          />
        </Stack>,
      )}
    </Stack>
  );
};
