import { ReferencePropertyDefinition } from '@eagle/common';
import { Brand, Country, Model } from '@eagle/core-data-types';
import { Skeleton } from '@mui/material';
import { FC } from 'react';
import { useFetchOneCache, usePromise } from '../../hooks';
import { CacheDataTypes, Undefinable } from '../../types';
import { referenceCollectionToCacheType } from './format.utils';

interface FormatReferenceProps {
  definition: ReferencePropertyDefinition;
  value: string;
}

export const FormatReference: FC<FormatReferenceProps> = (props) => {
  const cacheType = referenceCollectionToCacheType[props.definition.referenceCollection];

  if (!props.value || !cacheType) {
    return null;
  }

  return <FormatReferenceInner cacheType={cacheType} {...props} />;
};

interface FormatReferenceInnerProps extends FormatReferenceProps {
  cacheType: CacheDataTypes;
}

const FormatReferenceInner: FC<FormatReferenceInnerProps> = ({ cacheType, value }) => {
  const cache = useFetchOneCache(cacheType);
  const [result, , state] = usePromise<Undefinable<Brand | Model | Country>>(
    () => cache.one(value),
    [cache, value],
  );

  if (state === 'pending') {
    return <Skeleton variant="text" sx={{ maxWidth: 100, height: '1rem' }} />;
  }

  return <>{result?.display ?? value}</>;
};

export default FormatReference;
