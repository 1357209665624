/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';
import { useLocation } from 'react-router-dom';

export const GoogleAnalyticsTrackingWrapper: FC<{ children: ReactNode; options?: InitOptions }> = ({ children, options }): JSX.Element => {
  const location = useLocation();

  if (!options) return <>{children}</>;

  ReactGA.initialize([{
    trackingId: options.trackingId,
  }]);

  const trackPage = (page: string): void => {
    ReactGA.send({ hitType: 'pageview', page, ...options });
  };

  useEffect(() => {
    trackPage(location.pathname);
  }, [location]);

  return <>
    {children}
  </>;
};
