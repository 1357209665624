import { Box, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormatTimestamp } from '../format';
import { DateTimeTooltip } from '../tooltip';

export const DateDisplay: FC<{ date: Date; timezone: string }> = ({ date, timezone }): JSX.Element => {
  const dateTimeObject = DateTime.fromJSDate(date, { zone: timezone }).setLocale(navigator.language);
  const dateStamp = dateTimeObject.toJSDate();
  const whenLocal = dateTimeObject.toLocal();
  const { t } = useTranslation(['common']);
  const localTimeFormat = dateTimeObject.day !== whenLocal.day ? DateTime.DATETIME_MED_WITH_SECONDS : DateTime.TIME_WITH_SECONDS;
  const localTimeString = dateTimeObject.toLocaleString({ ...localTimeFormat });

  return (
    <Stack data-chromatic="ignore">
      <DateTimeTooltip
        disableHoverListener={whenLocal.offsetNameShort === dateTimeObject.offsetNameShort}
        display={
          <Box component="span" sx={{ display: 'flex', flexDirection: 'column' }}>
            <span><FormatTimestamp format="dateFull" value={dateStamp} /></span>
            <span><FormatTimestamp format="timeWithSeconds" value={dateStamp} /></span>
          </Box>
        }
        placement="top"
        title={t('common:component.alert-table.hint.tooltip', { dateTime: localTimeString, offsetName: dateTimeObject.offsetNameShort })}
      />
      <Typography color="text.secondary" variant="caption">
        <FormatTimestamp format="relative" value={dateStamp} />
      </Typography>
    </Stack>
  );
};
