import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTimeTooltip } from '../tooltip/date-time-tooltip';

interface Props {
  start: Date;
  timeZone?: string;
}

export const MediaItemLabel: FC<Props> = ({ start, timeZone }) => {
  const { t } = useTranslation(['common']);

  const localTime = DateTime.fromJSDate(start).setLocale(navigator.language);
  const localTimeString = localTime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  const mediaTime = localTime.setZone(timeZone ?? 'local');
  const mediaTimeString = mediaTime.toLocaleString(localTime.day !== mediaTime.day
    ? DateTime.DATETIME_MED_WITH_SECONDS
    : DateTime.TIME_WITH_SECONDS,
  );
  const isSameTimeZone = mediaTime.offsetNameShort === localTime.offsetNameShort;

  return (
    <DateTimeTooltip
      disableHoverListener={isSameTimeZone}
      display={isSameTimeZone
        ? localTimeString
        : t('common:component.media-data.labels.timeWithZone', {
          time: localTimeString,
          zone: localTime.offsetNameShort,
        })
      }
      size="small"
      title={t('common:component.alert-table.hint.tooltip', {
        dateTime: mediaTimeString,
        offsetName: mediaTime.offsetNameShort,
      })}
    />
  );
};
