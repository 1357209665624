import { AccountRequest, AccountType } from '@eagle/core-data-types';
import { Stack, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthenticated } from '../../../auth';
import { CacheDataTypes } from '../../../types';
import { testid } from '../../../util';
import { FetchOneOfAll } from '../../fetch';
import { convertPropertiesToObjectFormat } from '../../format';
import { LoadingButton } from '../../loading-button';
import { PropertiesElement } from '../../properties-element';
import { TextEditor } from '../../text-editor';
import { useStageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StakeholderState } from './stage-confirm-dialog-reducer';

interface StageConfirmDialogEditAccountRequestProps {
  accountRequest: AccountRequest;
  invalidateAccount: VoidFunction;
  stakeholderState: StakeholderState;
}

export const StageConfirmDialogEditAccountRequest: FC<StageConfirmDialogEditAccountRequestProps> = ({ accountRequest, stakeholderState, invalidateAccount }) => {
  const { dispatch } = useStageConfirmDialogContext();
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const { restClient } = useAuthenticated();
  const { enqueueSnackbar } = useSnackbar();
  const [values, setValues] = useState({
    display: accountRequest.targetAccountInfo.display,
    properties: accountRequest.targetAccountInfo.properties,
  });
  const [isLoading, setIsLoading] = useState(false);

  const isValid = values.display.trim().length > 0;

  const updateAccountRequest = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await restClient.accountRequest.partialUpdate(accountRequest._id,
        { targetAccountInfo: { ...accountRequest.targetAccountInfo, ...values, properties: convertPropertiesToObjectFormat(values.properties) } },
      );
      invalidateAccount();
      dispatch({ type: 'FINISH_STAKEHOLDER_EDIT_MODE', role: stakeholderState.role });
      enqueueSnackbar(t('common:common.hint.update-success', { entity: accountRequest.targetAccountInfo.display }), { variant: 'success' });
    }
    catch (e) {
      console.error(e);
      enqueueSnackbar(t('common:common.hint.update-failure', { entity: accountRequest.targetAccountInfo.display }), { variant: 'error' });
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack spacing={2} sx={{ marginTop: `${theme.spacing(2)} !important` }}>
      <TextEditor
        data-testid={testid`stage-dialog-${stakeholderState.role}-account-request-display-input`}
        disabled={isLoading}
        label={t('common:common.labels.account-name')}
        onKeyUp={(value) => {
          setValues((values) => ({ ...values, display: value }));
        }}
        required
        size="small"
        value={values.display}
      />
      {accountRequest.targetAccountInfo.accountTypeId && (
        <FetchOneOfAll
          dataType={CacheDataTypes.ACCOUNT_TYPE}
          id={accountRequest.targetAccountInfo.accountTypeId}
          renderFactory={(accountType: AccountType) => {
            return (
              <PropertiesElement
                size='small'
                disabled={isLoading}
                properties={values.properties ?? {}}
                propertyDefinitions={accountType.properties}
                onChange={(properties) => {
                  setValues((values) => ({ ...values, properties }));
                }}
              />
            );
          }}
        />
      )}
      <LoadingButton
        data-testid={testid`stage-dialog-${stakeholderState.role}-account-request-update-button`}
        disabled={!isValid}
        loadingCaption={t('common:common.labels.loading')}
        sx={{ alignSelf: 'flex-end' }}
        task={updateAccountRequest}
        variant='contained'
      >
        {t('common:common.action.update')}
      </LoadingButton>
    </Stack>
  );
};
