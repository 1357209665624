import { TypeDefinitionTypes } from '@eagle/common';
import { Thing, ThingType } from '@eagle/core-data-types';
import { FC } from 'react';
import { CacheDataTypes } from '../../../types';
import { BoldMatchedText } from '../../../util';
import { FetchOne, FetchOneOfAll } from '../../fetch';
import { FormatProperty, getMaybePropertyValue } from '../../format';
import { BasicColumn } from '../basic-column';

interface Props {
  id?: string;
  thing?: Thing;
  property: string;
  shared?: boolean;
}

const Column: FC<{ thing: Thing; property: string; shared?: boolean }> = ({ thing, property, shared }) => {
  const propertyType = shared ? 'sharedProperties' : 'properties';
  return <FetchOneOfAll dataType={CacheDataTypes.THING_TYPE} id={thing.thingTypeId} notFoundFactory={() => <></>} renderFactory={(thingType: ThingType) => {
    const definition = thingType[propertyType].definitions[property];
    const value = thing[propertyType][property];
    const propertyText = getMaybePropertyValue(value);

    if (!definition || !value) {
      return <></>;
    }

    if (definition.type === TypeDefinitionTypes.TEXT || definition.type === TypeDefinitionTypes.PHONE) {
      return <BasicColumn label={definition.label || ''}>
        <BoldMatchedText text={propertyText as string} />
      </BasicColumn>;
    }

    return (
      <BasicColumn label={definition.label}>
        <FormatProperty value={value || null} definition={definition} />
      </BasicColumn>
    );
  }} />;
};

export const ThingPropertyColumn: FC<Props> = ({ id, thing, property, shared }) => {
  if ((!id && !thing) || !property) {
    return <></>;
  }

  if (id) {
    return <FetchOne
      dataType={CacheDataTypes.THING}
      id={id}
      renderFactory={(thing: Thing) => {
        return <Column thing={thing} property={property} shared={shared} />;
      }}
    />;
  }

  if (thing) {
    return <Column thing={thing} property={property} shared={shared} />;
  }

  return <></>;
};
