import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

export interface FormatAddressProps {
  addressUnavailableText?: string;
  'data-testid'?: string;
  value?: string[];
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'button' | 'overline' | 'inherit';
  sx?: SxProps;
}

export const FormatAddress: FC<FormatAddressProps> = ({ addressUnavailableText, value, variant = 'body2', sx, ...props }): JSX.Element => {
  if (!value || !value.length) {
    return addressUnavailableText
      ? <Typography variant={variant} fontStyle="italic" sx={sx} data-testid={props['data-testid']}>{addressUnavailableText}</Typography>
      : <></>;
  }
  if (value.length === 1) return <Typography data-testid={props['data-testid']} variant={variant} sx={sx}>{value[0]}</Typography>;
  return <Typography variant={variant} data-testid={props['data-testid']} sx={sx}>{value.join(', ')}</Typography>;
};
