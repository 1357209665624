/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Feature, FeatureType } from '@eagle/core-data-types';
import { useAuthenticated } from '../auth';
import { useFetchAllCache, usePromise } from '../hooks';
import { CacheDataTypes, Nullable } from '../types';
import { filterDeletedCache } from './filter';

export const getFeatureType = (alertTypeId: Nullable<string>): Nullable<string> => {
  const featureTypesCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);

  const [featureTypes] = usePromise(
    Promise.resolve(featureTypesCache.all<FeatureType>()),
    [featureTypesCache],
  );

  if (!alertTypeId) {
    return null;
  }

  return featureTypes?.find((featureType) => alertTypeId in featureType.alerts)?._id ?? null;
};

interface AlertableFeatures {
  alertableFeatures: Feature[];
  alertableFeaturesError: Error;
  alertableFeatureTypes: FeatureType[];
  alertableFeatureTypesError: Error;
}

export const useAlertableFeatures = (): Partial<AlertableFeatures> => {
  const { axios } = useAuthenticated();
  const featureCache = useFetchAllCache(CacheDataTypes.FEATURE);
  const featureTypeCache = useFetchAllCache(CacheDataTypes.FEATURE_TYPE);

  const [alertableFeatureTypes, alertableFeatureTypesError] = usePromise<FeatureType[]>(
    async () => {
      const featureTypes = await filterDeletedCache<FeatureType>(featureTypeCache);
      return featureTypes.filter(({ alerts }) => Object.keys(alerts).length > 0);
    },
    [axios, featureTypeCache],
  );

  const [alertableFeatures, alertableFeaturesError] = usePromise<Feature[]>(
    async () => {
      if (!alertableFeatureTypes) return Promise.resolve([]);
      const featureTypes = await filterDeletedCache<Feature>(featureCache);
      return featureTypes.filter(
        ({ featureTypeId }) => alertableFeatureTypes?.some(({ _id }) => featureTypeId === _id),
      );
    },
    [axios, alertableFeatureTypes, featureCache],
  );

  return {
    alertableFeatures,
    alertableFeaturesError,
    alertableFeatureTypes,
    alertableFeatureTypesError,
  };
};
