import { FC } from 'react';

export interface FormatTextProps {
  value: string;
}

export const FormatText: FC<FormatTextProps> = ({ value }) => {
  return <>{value}</>;
};

export default FormatText;
